















import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue';
import Card from '@/components/card/Card.vue';
import PortalTable from '../components/PortalTable.vue';
import { PortalListingData } from '../domain/PortalListingData';
import { PortalTarget } from '../domain/PortalTarget';
import ListingAddButton from '@/components/ListingAddButton.vue';
import { PortalService } from '../services/PortalService';
import { PortalSearchCriteria } from '../domain/PortalSearchCriteria';

@Component({
    components: { Page, Card, PortalTable, ListingAddButton },
})
export default class PortalListing extends Vue {
    private isLoading: boolean = false;

    private portals: PortalListingData[] = [];
    private pageCount: number = 1;

    private searchCriteria: PortalSearchCriteria = {
        name: '',
        target: '',
        page: 0,
    };

    private async mounted() {
        await this.fetchPortalsWithCriteria();
    }

    private async fetchPortalsWithCriteria() {
        this.isLoading = true;
        try {
            const { portals, pageCount } = await PortalService
                .getPortalsFor(this.searchCriteria, this.$store.getters['sessionStore/token']);
            this.portals = portals;
            this.pageCount = pageCount;
        } catch {
            this.portals = [];
        }
        this.isLoading = false;
    }

    private async onPageChange(page: number) {
        this.searchCriteria.page = page;
        await this.fetchPortalsWithCriteria();
    }

    private async onSearchCriteriaUpdate(searchCriteria: PortalSearchCriteria) {
        this.searchCriteria = searchCriteria;
        await this.fetchPortalsWithCriteria();
    }
}
