









































import { Component, Vue, Prop } from 'vue-property-decorator';
import { PortalListingData } from '../domain/PortalListingData';
import PortalTargetTag from './PortalTargetTag.vue';
import { PortalSearchCriteria } from '../domain/PortalSearchCriteria';
import { PortalTarget } from '../domain/PortalTarget';
import { formatAsFirstLetterCap } from '@/utils/FormatAsFirstLetterCap';

@Component({
    components: { PortalTargetTag },
})
export default class PortalTable extends Vue {
    @Prop() private readonly portals!: PortalListingData[];
    @Prop() private readonly isLoading!: boolean;
    @Prop() private readonly pageCount!: number;
    @Prop() private searchCriteria!: PortalSearchCriteria;
    private readonly formatAsFirstLetterCap = formatAsFirstLetterCap;

    private search() {
        this.$emit('updateSearch', this.searchCriteria);
    }

    private editPortal(portal: PortalListingData) {
        this.$router.push(`view-portal/${portal.id}`);
    }

    get portalTargets() {
        const targets = [];
        for (const target in PortalTarget) {
            if (PortalTarget.hasOwnProperty(target)) {
                targets.push(this.formatAsFirstLetterCap(target));
            }
        }

        return targets;
    }

    private onPageChange(page: number) {
        this.$emit('changePage', page - 1);  // Lors d'un click sur la page 1, on doit retourner la page 0
    }
}
