




import { Component, Vue, Prop } from 'vue-property-decorator';
import { PortalTarget } from '../domain/PortalTarget';

@Component
export default class PortalTargetTag extends Vue {
    @Prop() private readonly target!: PortalTarget;

    get type() {
        if (this.target === PortalTarget.SELF_SERVICE_ADVERTISING) {
            return 'success';
        }

        return 'primary';
    }

    get text() {
        if (this.target === PortalTarget.SELF_SERVICE_ADVERTISING) {
            return 'Publicité libre service';
        }

        return 'NéoTv';
    }
}
